define('lxso/components/mandatory-fees/dock-fees-dialog', ['exports', 'lxso/utils/ticket-type-validator'], function (exports, _ticketTypeValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    isOpen: false,
    dockFees: null,
    selectedTicketTypes: Ember.A(), // Initialize to an empty array
    removedTicketTypesDock: [],

    init: function init() {
      this._super.apply(this, arguments);

      var currencyDetails = this.get('currencyDetails');
      console.log('currencyDetails inside dockFess js init method', currencyDetails);

      // Fallback to a regular check for currencyDetails
      var defaultCurrency = null;
      if (currencyDetails && Array.isArray(currencyDetails)) {
        defaultCurrency = currencyDetails.findBy('default', true);
      }
      console.log('defaultCurrency is : ', defaultCurrency);

      if (defaultCurrency) {
        this.set('selectedCurrency', defaultCurrency.currency_cod); // Set the default currency
      }

      console.log('selectedCurrency in init method:', this.get('selectedCurrency'));

      // Initialize selectedTicketTypes based on prefilled values if dockFees are available
      var dockFees = this.get('dockFees');
      if (dockFees && dockFees.length > 0) {
        var ticketTypes = dockFees[0].ticketTypes || Ember.A();
        var selectedTypes = ticketTypes.map(function (type) {
          return {
            amount: type.amount,
            selectedOption: type.ticketTypeName
          };
        });

        this.set('selectedTicketTypes', Ember.A(selectedTypes));
      } else {
        // Initialize with empty ticket type if no dockFees are present
        this.set('selectedTicketTypes', Ember.A([{ amount: null, selectedOption: '' }]));
      }
    },


    // for prefilling the form

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      // If dockFees is provided, use its values to prefill the form
      var dockFees = this.get('dockFees') || [];
      console.log('dockFees inside didReceiveAttrs', dockFees);
      console.log('dockFees.length inside didReceiveAttrs', dockFees.length);

      if (dockFees.length === 1 && dockFees[0].ticketTypes) {
        var firstFee = dockFees[0];
        console.log('firstFee inside didReceiveAttrs', firstFee);

        // Map the ticket types from the response
        var editingTicketTypes = firstFee.ticketTypes.map(function (ticketType) {
          return {
            amount: ticketType.amount,
            selectedOption: ticketType.ticketType,
            selectedTicketTypeId: ticketType.ticketTypeId,
            additionalFeeId: ticketType.additionalFeeId,
            mandatory: ticketType.mandatory,
            status: ticketType.status
          };
        });

        console.log('editingTicketTypes in component js', editingTicketTypes);

        // Set the description, startDate, endDate, and ticketTypes using the first admission fee
        this.setProperties({
          description: firstFee.description || '',
          startDate: firstFee.startDate || '',
          endDate: firstFee.endDate || '',
          selectedCurrency: this.get('selectedCurrency'),
          additionalFeeTypesID: firstFee.additionalFeeTypesID || '',
          additionalFeeDescription: firstFee.additionalFeeDescription || '',
          editingTicketTypes: Ember.A(editingTicketTypes) // Set the ticket types and amounts
        });
      } else if (dockFees.length > 0 && !dockFees[0].ticketTypes) {
        var _firstFee = dockFees[0];
        console.log('firstFee inside else if didReceiveAttrs', _firstFee);

        var _editingTicketTypes = dockFees.map(function (fee) {
          return {
            amount: fee.amount,
            selectedOption: fee.ticketType, // or another suitable label for the ticket type
            selectedTicketTypeId: fee.ticketTypeId,
            additionalFeeId: fee.additionalFeeId,
            mandatory: fee.mandatory,
            status: fee.status
          };
        });

        console.log('editingTicketTypes inside else if didReceiveAttrs', _editingTicketTypes);

        this.setProperties({
          description: _firstFee.description || '',
          startDate: _firstFee.startDate || '',
          endDate: _firstFee.endDate || '',
          selectedCurrency: _firstFee.selectedCurrency,
          additionalFeeTypesID: _firstFee.additionalFeeTypesID || '',
          additionalFeeDescription: _firstFee.additionalFeeDescription || '',
          editingTicketTypes: Ember.A(_editingTicketTypes) // Set the ticket types and amounts for all fees
        });
      } else {
        // Default values if no admission fees provided
        this.setProperties({
          description: '',
          startDate: '',
          endDate: '',
          selectedCurrency: this.get('selectedCurrency'),
          editingTicketTypes: Ember.A([{ amount: null, selectedOption: '' }])
        });
      }
    },


    actions: {
      addTicketType: function addTicketType() {
        this.get('editingTicketTypes').pushObject({ amount: null, selectedOption: '' });
      },
      removeTicketType: function removeTicketType(ticketType) {
        if (!ticketType) {
          console.error('No ticketType provided for removal.');
        }

        // Log the ticketType being removed for debugging
        console.log('Removing ticketType:', ticketType);

        this.get('removedTicketTypesDock').push(ticketType);

        var updatedTicketTypes = this.get('editingTicketTypes').filter(function (item, index) {
          console.log('item.additionalFeeId', item.additionalFeeId);
          return item.additionalFeeId !== ticketType.additionalFeeId;
        });

        // Update the state with the filtered list
        this.set('editingTicketTypes', updatedTicketTypes);
        console.log('Updated ticketTypes:', updatedTicketTypes);
        console.log('this.get(removedTicketTypesDock):', this.get('removedTicketTypesDock'));
      },
      handleTicketTypeChange: function handleTicketTypeChange(index, selectedValue) {
        var _this = this;

        var editingTicketTypes = this.get('editingTicketTypes'); // Get the ticket types
        var ticketTypeDisplayNames = this.get('ticketTypeDisplayNames'); // Get the display names

        // Validate when ticket type is changed
        // validateTicketType(editingTicketTypes, ticketTypeDisplayNames, index, selectedValue);
        (0, _ticketTypeValidator.default)(editingTicketTypes, ticketTypeDisplayNames, index, selectedValue, function (message) {
          _this.sendAction('showAlert', message); // Ensure this invokes the passed action
        });

        // Additional logic if needed when the ticket type changes
        console.log('Handling ticket type change for index ' + index + ', selected value: ' + selectedValue);
      },
      save: function save() {
        var applyToAllOffers = this.get('applyToAllOffers');
        console.log('applyToAllOffers', applyToAllOffers);

        var offerId = this.get('offerId');
        var activityId = this.get('activityId');
        var additionalFeeTypesID = this.get('additionalFeeTypesID');
        console.log('additionalFeeTypesID inside save method', additionalFeeTypesID);

        var invalidTicketType = this.get('editingTicketTypes').find(function (ticketType) {
          console.log('ticketType.amount...', ticketType.amount);
          return ticketType.amount <= 0; // Check if amount is less than or equal to zero
        });

        if (invalidTicketType) {
          // If there's an invalid ticket type, show an alert message
          this.sendAction('showAlert', 'Please make sure the amount for all ticket types is greater than zero.');
          return; // Prevent form submission
        }

        var commonFields = {
          description: this.get('description'),
          startDate: this.get('startDate'),
          endDate: this.get('endDate'),
          selectedCurrency: this.get('selectedCurrency'),
          additionalFeeDescription: this.get('additionalFeeDescription'),
          additionalFeeTypesID: this.get('additionalFeeTypesID'),
          offerId: offerId,
          activityId: activityId,
          mandatory: this.get('mandatory')
          // status: this.get('status')
        };

        console.log('editingTicketTypes inside save', this.get('editingTicketTypes'));

        // Iterate over ticketTypes and create separate objects for each
        var dockFees = this.get('editingTicketTypes').filter(function (ticket) {
          return ticket.selectedOption !== '';
        }) // Filter out tickets without a selected option
        .map(function (ticketType) {
          var ticketTypeId = ticketType.selectedTicketTypeId;
          var additionalFeeId = ticketType.additionalFeeId;
          var mandatory = ticketType.mandatory;
          var status = ticketType.status;
          console.log('Mapped ticketTypeId:', ticketTypeId);
          console.log('Ticket Type:', ticketType);
          console.log('additionalFeeId inside save:', additionalFeeId);

          if (!ticketTypeId) {
            console.error('Ticket Type ID is missing for', ticketType);
          }

          return Object.assign({}, commonFields, {
            amount: ticketType.amount || '0.000000', // Set default amount if missing
            ticketTypeId: ticketTypeId, // Use the mapped ticketTypeId
            additionalFeeId: additionalFeeId,
            mandatory: mandatory,
            status: status,
            ticketType: ticketType.selectedOption
          });
        });

        // Handling removed ticket types
        var removedTicketTypesDock = this.get('removedTicketTypesDock').map(function (ticket) {
          return Object.assign({}, commonFields, {
            ticketTypeId: ticket.selectedTicketTypeId,
            additionalFeeId: ticket.additionalFeeId,
            amount: ticket.amount || '0.000000',
            status: 'D', // Set status to "D" for deleted
            mandatory: ticket.mandatory,
            ticketType: ticket.selectedOption
          });
        });

        // Combine dockFees and removedTicketTypes
        var finalFeesToSave = [].concat(_toConsumableArray(dockFees), _toConsumableArray(removedTicketTypesDock));
        console.log('removedTicketTypes in component save', removedTicketTypesDock);
        console.log('finalFeesToSave in component save', finalFeesToSave);

        this.get('onSave')(dockFees, removedTicketTypesDock);

        // this.get('onSave')(dockFees);
        console.log('dockFees in component save', dockFees);
      },
      close: function close() {
        this.get('onClose')();
      }
    }
  });
});